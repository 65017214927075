<template>
  <v-container fluid class="d-flex flex-column">
    <v-card flat>
      <CategoryTitle :category="category" />

      <div class="mt-4" v-if="showNewPasswordPanel">
        <v-card-title class="px-0">Inserisci la nuova passoword</v-card-title>
        <v-card-text class="pa-0">
          <v-form
            v-on:submit.prevent
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="password"
                  :label="$t('profile.newPassword') + ' *'"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  @change="validate"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="passwordConfirm"
                  :label="$t('profile.passwordConfirm') + ' *'"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordConfirmRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  @change="validate"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
            @click="handleResetPasswordConfirm"
            class="reset-button"
            color="primary"
            large
            :block="$vuetify.breakpoint.xs"
            min-width="200"
            height="40"
            depressed
            :loading="loading"
            >{{ $t("login.submitResetPassword") }}</v-btn
          >
        </v-card-actions>
      </div>

      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";

import category from "~/mixins/category";

import userService from "~/service/userService";

import {
  requiredValue,
  isPasswordMinimumLength
} from "~/validator/validationRules";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPassword",
  mixins: [category],
  data() {
    return {
      valid: true,
      loading: false,
      lazy: true,
      response: {},
      showNewPasswordPanel: false,
      password: null,
      passwordConfirm: null,
      showPassword: false,
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ]
    };
  },
  props: {
    email: { type: String, default: undefined },
    token: { type: String, default: undefined }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async checkToken(email, token) {
      let _this = this;
      _this.response = {};
      try {
        _this.loading = true;
        let res = await userService.resetPasswordTokenCheck(email, token);

        if (res.response?.status == 0) {
          this.showNewPasswordPanel = true;
          if (token) {
            this.token = token;
          }
          _this.response = res;
        } else {
          this.showNewPasswordPanel = false;
          _this.response = res;
        }
      } catch (err) {
        this.showNewPasswordPanel = false;
        if (err.errors) _this.response = err;
      } finally {
        this.loading = false;
      }
    },
    async handleResetPasswordConfirm(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.valid) {
        _this.loading = true;
        let res = null;
        try {
          res = await userService.resetPassword(
            _this.email,
            _this.token,
            _this.password
          );
          if (res.response?.status == 0) {
            _this.$router.push({ name: "ResetPasswordSent" });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          if (err.errors) {
            _this.response = err;
          } else {
            _this.response = err.response.data.response;
          }
        } finally {
          _this.loading = false;
        }
      }
    }
  },
  mounted() {
    if (this.token && this.email) {
      this.checkToken(this.email, this.token);
    } else {
      this.response = {
        errors: this.$t("resetPassword.missingData")
      };
    }
  }
};
</script>
